<template>
  <div>
    <ConfirmDialog />
    <ChangeSeller
      :showToast="true"
      :show="changeSellerInfo.show"
      :currentSeller="changeSellerInfo.currentSeller"
      :contractId="changeSellerInfo.contractId"
      v-on:cancel="changeSellerCancel"
      v-on:setContractSeller="changedSeller"
    />
    <ExternalManager
      :showToast="true"
      :show="changeExternalsInfo.show"
      :currentExternalManager="changeExternalsInfo.currentExternalManager"
      :currentExternalDirection="changeExternalsInfo.currentExternalDirection"
      :currentExternalCoordinator="
        changeExternalsInfo.currentExternalCoordinator
      "
      :currentExternalConsultant="changeExternalsInfo.currentExternalConsultant"
      :currentExternalCustomerManagement="
        changeExternalsInfo.currentExternalCustomerManagement
      "
      :contractId="changeExternalsInfo.contractId"
      v-on:cancel="changeExternalManagerCancel"
      v-on:setContractExternalManager="changedExternalManager"
    />
    <h1>Gestão de Contratos</h1>
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        :loader="streakLoading"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :value="contracts"
        :expandedRows.sync="expandedRows"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        sortMode="single"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        sortField="days_to_expire"
        :sortOrder="1"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm"
              label="Mostrar todos os contratos"
              @click="showAll()"
            />
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #expansion="slotProps">
          <div class="contracts-info p-col-12 p-mt-2">
            <h5><b>Projeto:</b> {{ slotProps.data.entity_name }}</h5>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-5">
                <div class="p-d-flex p-flex-column">
                  <span><b>Cliente: </b>{{ slotProps.data.client_name }}</span>
                  <span><b>Produto: </b>{{ slotProps.data.product }}</span>
                  <span>
                    <b>Referência: </b>{{ slotProps.data.reference }}</span
                  >
                  <span>
                    <b>Referência Externa: </b
                    >{{ slotProps.data.external_reference }}
                  </span>
                  <span
                    ><b>Data Inicio: </b>{{ slotProps.data.start_date }}</span
                  >
                  <span><b>Data Fim: </b>{{ slotProps.data.end }}</span>
                  <span><b>Duração: </b>{{ slotProps.data.duration }}</span>
                  <span
                    ><b>PO Atual: </b
                    >{{ slotProps.data.current_reference }}</span
                  >
                  <span
                    ><b>Próxima PO: </b
                    >{{ slotProps.data.next_reference }}</span
                  >
                  <span><b>Vendedor: </b>{{ slotProps.data.seller }}</span>
                  <br />
                  <span>
                    <b>Comercial: </b>{{ slotProps.data.external_manager_name }}
                  </span>
                  <span
                    ><b>Comercial Telefone: </b
                    >{{
                      formatPhoneNumber(
                        slotProps.data.external_manager_telephone
                      )
                    }}</span
                  >
                  <span
                    ><b>Comercial Email: </b
                    >{{ slotProps.data.external_manager_email }}</span
                  >
                  <span
                    ><b>Comercial Tipo: </b
                    >{{ slotProps.data.external_manager_type }}</span
                  >
                  <br />
                  <span>
                    <b>Consultor: </b
                    >{{ slotProps.data.external_consultant_name }}
                  </span>
                  <span
                    ><b>Consultor Telefone: </b
                    >{{
                      formatPhoneNumber(
                        slotProps.data.external_consultant_telephone
                      )
                    }}</span
                  >
                  <span
                    ><b>Consultor Email: </b
                    >{{ slotProps.data.external_consultant_email }}</span
                  >
                  <span
                    ><b>Consultor Tipo: </b
                    >{{ slotProps.data.external_consultant_type }}</span
                  >
                  <br />
                  <span>
                    <b>Coordenador: </b
                    >{{ slotProps.data.external_coordinator_name }}
                  </span>
                  <span
                    ><b>Coordenador Telefone: </b
                    >{{
                      formatPhoneNumber(
                        slotProps.data.external_coordinator_telephone
                      )
                    }}</span
                  >
                  <span
                    ><b>Coordenador Email: </b
                    >{{ slotProps.data.external_coordinator_email }}</span
                  >
                  <span
                    ><b>Coordenador Tipo: </b
                    >{{ slotProps.data.external_coordinator_type }}</span
                  >
                  <br />
                  <span>
                    <b>Direção: </b>{{ slotProps.data.external_direction_name }}
                  </span>
                  <span
                    ><b>Direção Telefone: </b
                    >{{
                      formatPhoneNumber(
                        slotProps.data.external_direction_telephone
                      )
                    }}</span
                  >
                  <span
                    ><b>Direção Email: </b
                    >{{ slotProps.data.external_direction_email }}</span
                  >
                  <span
                    ><b>Direção Tipo: </b
                    >{{ slotProps.data.external_direction_type }}</span
                  >
                  <!-- <br />
                  <span>
                    <b>Gestão de Clientes: </b
                    >{{ slotProps.data.external_customer_management_name }}
                  </span>
                  <span
                    ><b>Gestão de Clientes Telefone: </b
                    >{{
                      formatPhoneNumber(
                        slotProps.data.external_customer_management_telephone
                      )
                    }}</span
                  >
                  <span
                    ><b>Gestão de Clientes Email: </b
                    >{{
                      slotProps.data.external_customer_management_email
                    }}</span
                  >
                  <span
                    ><b>Gestão de Clientes Tipo: </b
                    >{{
                      slotProps.data.external_customer_management_type
                    }}</span
                  > -->
                </div>
              </div>
              <div class="p-field p-col-12 p-md-7">
                <div class="p-d-flex p-flex-column">
                  <span><b>Ordem: </b>{{ slotProps.data.order }}</span>
                  <span
                    ><b>Tipo de Suporte: </b
                    >{{ slotProps.data.support_type }}</span
                  >
                  <span
                    ><b>Inclui Substituição de Equipamentos: </b
                    >{{ slotProps.data.replacement_equipment_included }}</span
                  >
                  <span><b>SLA: </b>{{ slotProps.data.sla }}</span>
                  <span
                    ><b>SLA Remoto: </b>{{ slotProps.data.sla_remote }}</span
                  >
                  <span
                    ><b>Periodo de Suporte: </b
                    >{{ slotProps.data.support_period }}</span
                  >
                  <span
                    ><b>Especificações: </b
                    >{{ slotProps.data.specification }}</span
                  >
                </div>
              </div>
              <div class="p-d-flex p-jc-end p-col-12">
                <span class="p-col-1">
                  <Button
                    label="Enviar Email"
                    icon="pi pi-envelope"
                    @click="composeEmail(slotProps.data)"
                  />
                </span>
              </div>
            </div>
          </div>
        </template>
        <Column
          :expander="true"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        >
        </Column>
        <Column
          sortable
          field="id"
          header="Id"
          :headerStyle="{ 'min-width': '4%', 'max-width': '4%' }"
          :filterHeaderStyle="{ 'min-width': '4%', 'max-width': '4%' }"
          :bodyStyle="{ 'min-width': '4%', 'max-width': '4%' }"
        />
        <Column
          sortable
          field="client_name"
          header="Cliente"
          filterField="client_name"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column sortable field="entity_name" header="Projeto" filterField="entity_name">
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="months_to_expire"
          header="Estado"
          filterField="months_to_expire"
          sortable
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon p-mr-1"
              :style="getIconColor(slotProps.data.months_to_expire)"
            ></i>
            <span v-if="slotProps.data.months_to_expire == 0"> Este Mês </span>
            <span v-else-if="slotProps.data.months_to_expire == -1">
              Expirado
            </span>
            <span v-else> {{ slotProps.data.months_to_expire }} Meses </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="streakStatus"
              optionLabel="title"
              optionValue="id"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column sortable field="seller" header="Vendedor" filterField="seller">
          <template #body="slotProps">
            <span
              v-if="can('editContractsSeller')"
              class="clickable-item"
              v-tooltip="'Alterar Vendedor'"
              @click="changeSeller(slotProps.data)"
            >
              {{ slotProps.data.seller ? slotProps.data.seller : "_______" }}
            </span>
            <span v-else>
              {{ slotProps.data.seller }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="external_manager_name"
          header="Comercial"
          filterField="external_manager_name"
        >
          <template #body="slotProps">
            <span
              v-if="can('editContractsExternalManager')"
              class="clickable-item"
              @click="changeExternalManager(slotProps.data)"
            >
              {{
                slotProps.data.external_manager_name
                  ? slotProps.data.external_manager_name
                  : "_______"
              }}
            </span>
            <span v-else>
              {{ slotProps.data.external_manager_name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="external_direction_name"
          header="Direção"
          filterField="external_direction_name"
        >
          <template #body="slotProps">
            <span
              v-if="can('editContractsExternalManager')"
              class="clickable-item"
              @click="changeExternalManager(slotProps.data)"
            >
              {{
                slotProps.data.external_direction_name
                  ? slotProps.data.external_direction_name
                  : "_______"
              }}
            </span>
            <span v-else>
              {{ slotProps.data.external_direction_name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="external_coordinator_name"
          header="Coordenador"
          filterField="external_coordinator_name"
        >
          <template #body="slotProps">
            <span
              v-if="can('editContractsExternalManager')"
              class="clickable-item"
              @click="changeExternalManager(slotProps.data)"
            >
              {{
                slotProps.data.external_coordinator_name
                  ? slotProps.data.external_coordinator_name
                  : "_______"
              }}
            </span>
            <span v-else>
              {{ slotProps.data.external_coordinator_name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="external_consultant_name"
          header="Consultor"
          filterField="external_consultant_name"
        >
          <template #body="slotProps">
            <span
              v-if="can('editContractsExternalManager')"
              class="clickable-item"
              @click="changeExternalManager(slotProps.data)"
            >
              {{
                slotProps.data.external_consultant_name
                  ? slotProps.data.external_consultant_name
                  : "_______"
              }}
            </span>
            <span v-else>
              {{ slotProps.data.external_consultant_name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column sortable field="end" header="Data Fim" filterField="end">
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
              :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
              :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
              :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
            />
          </template>
        </Column>
        <Column
          sortable
          field="days_to_expire"
          header="Expira em"
          filterField="days_to_expire"
          :headerStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :filterHeaderStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :bodyStyle="{ 'min-width': '7%', 'max-width': '7%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.days_to_expire }} dias
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="emailCompose.show"
      :containerStyle="{ width: $isMobile() ? '100vw' : '40vw' }"
      :position="'bottomright'"
      :maximizable="true"
      v-on:hide="cancelSend"
      :header="emailCompose.header"
      :style="{ position: 'relative' }"
    >
      <Loading :active.sync="emailCompose.loading" :isFullPage="false" />
      <div class="p-col-12">
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>Email Tipo</b>
          </div>
          <div class="p-col-11">
            <Dropdown
              name="sellerUser"
              :options="emailsTypes"
              :filter="true"
              :optionLabel="'name'"
              :optionValue="'id'"
              :dataKey="'id'"
              v-model="emailCompose.template"
              style="width: 100%"
              v-bind:class="['form-control']"
              @change="templateMailChanged"
            >
            </Dropdown>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>Para</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              v-bind:class="[
                {
                  'p-invalid': emailCompose.response.errorTo,
                },
                'p-ml-1',
              ]"
              separator=","
              v-model="emailCompose.response.to"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
            <small
              v-if="emailCompose.response.errorTo"
              class="p-error"
              role="alert"
            >
              Destinatário está vazio
            </small>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>CC</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              separator=","
              v-model="emailCompose.response.cc"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>BCC</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              separator=","
              v-model="emailCompose.response.bcc"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>Assunto</b>
          </div>
          <div class="p-col-11">
            <Textarea
              type="text"
              name="subject"
              :autoResize="true"
              rows="1"
              style="width: 100%"
              v-model="emailCompose.response.subject"
            />
            <small
              v-if="emailCompose.response.errorSubject"
              class="p-error"
              role="alert"
            >
              Assunto está vazio
            </small>
          </div>
        </div>
        <div class="p-col-12">
          <Editor
            name="body"
            v-model="emailCompose.response.content"
            editorStyle="height: 200px"
            v-bind:class="[
              { 'p-invalid': emailCompose.response.errorContent },
              'form-control',
            ]"
            @text-change="() => (emailCompose.templateChanged = true)"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <small
            class="p-error"
            role="alert"
            v-if="emailCompose.response.errorContent"
          >
            O email está vazio
          </small>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <FileUpload
            :ref="'attachmentInput'"
            :name="'attachments[]'"
            chooseLabel="Adicionar Anexo"
            :multiple="true"
            :showUploadButton="false"
            :showCancelButton="false"
            :maxFileSize="2000000000"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Enviar'"
          icon="pi pi-send"
          class="p-button-sm p-button-success"
          @click="sendEmail()"
        />
        <Button
          :label="'Cancelar'"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="cancelSend()"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import chroma from "chroma-js";
import { FilterMatchMode } from "primevue/api/";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import ChangeSeller from "../components/contracts/ChangeSeller.vue";
import ExternalManager from "../components/contracts/ChangeExternalManager.vue";
import contractsService from "../services/contracts.services";
export default {
  name: "ExpiringContracts",
  components: {
    Loading,
    W4Mstreak,
    ChangeSeller,
    ExternalManager,
  },
  data() {
    return {
      loading: true,
      streakLoading: true,
      streakStatus: null,
      currentState: {},
      contracts: [],
      expandedRows: [],
      emailsTypes: [],
      emailCompose: {
        loading: false,
        show: false,
        header: null,
        template: null,
        templateChanged: null,
        contract: null,
        response: {
          to: [],
          cc: [],
          bcc: [],
          subject: "",
          content: "",
          errorTo: false,
          errorSubject: false,
          errorContent: false,
          errorStatus: false,
        },
      },
      changeSellerInfo: {
        show: false,
        currentSeller: null,
        contractId: null,
      },
      changeExternalsInfo: {
        show: false,
        currentExternalManager: null,
        currentExternalDirection: null,
        currentExternalCoordinator: null,
        currentExternalConsultant: null,
        currentExternalCustomerManagement: null,
        contractId: null,
      },
      filters: {
        entity_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        days_to_expire: { value: null, matchMode: FilterMatchMode.EQUALS },
        months_to_expire: { value: null, matchMode: FilterMatchMode.EQUALS },
        external_direction_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        external_customer_management_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        external_manager_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        external_coordinator_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        external_consultant_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        end: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      colors: [],
    };
  },
  async created() {
    this.colors = chroma.scale(["yellow", "orange", "red"]).colors(8);
    await this.initStreak();
    this.emailsTypes = await contractsService.getEmailsTypes();
    this.loading = false;
  },
  methods: {
    async initStreak() {
      this.streakLoading = true;
      this.streakStatus = [
        {
          backgroudColor: this.colors[0],
          textColor: this.getReadableTextColor(this.colors[0]),
          title: "6 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 6,
          index: 0,
        },
        {
          backgroudColor: this.colors[1],
          textColor: this.getReadableTextColor(this.colors[1]),
          title: "5 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 5,
          index: 1,
        },
        {
          backgroudColor: this.colors[2],
          textColor: this.getReadableTextColor(this.colors[2]),
          title: "4 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 4,
          index: 2,
        },
        {
          backgroudColor: this.colors[3],
          textColor: this.getReadableTextColor(this.colors[3]),
          title: "3 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 3,
          index: 3,
        },
        {
          backgroudColor: this.colors[4],
          textColor: this.getReadableTextColor(this.colors[4]),
          title: "2 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 2,
          index: 4,
        },
        {
          backgroudColor: this.colors[5],
          textColor: this.getReadableTextColor(this.colors[5]),
          title: "1 Meses",
          subtitle: "",
          counter: 0,
          active: false,
          id: 1,
          index: 5,
        },
        {
          backgroudColor: this.colors[6],
          textColor: this.getReadableTextColor(this.colors[6]),
          title: "Este Mês",
          subtitle: "",
          counter: 0,
          active: false,
          id: 0,
          index: 6,
        },
        {
          backgroudColor: this.colors[7],
          textColor: this.getReadableTextColor(this.colors[7]),
          title: "Expirados",
          subtitle: "",
          counter: 0,
          active: false,
          id: -1,
          index: 7,
        },
      ];

      this.streakStatus = [
        {
          backgroudColor: this.colors[0],
          textColor: this.getReadableTextColor(this.colors[0]),
          title: "6 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(6),
          active: false,
          id: 6,
          index: 0,
        },
        {
          backgroudColor: this.colors[1],
          textColor: this.getReadableTextColor(this.colors[1]),
          title: "5 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(5),
          active: false,
          id: 5,
          index: 1,
        },
        {
          backgroudColor: this.colors[2],
          textColor: this.getReadableTextColor(this.colors[2]),
          title: "4 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(4),
          active: false,
          id: 4,
          index: 2,
        },
        {
          backgroudColor: this.colors[3],
          textColor: this.getReadableTextColor(this.colors[3]),
          title: "3 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(3),
          active: false,
          id: 3,
          index: 3,
        },
        {
          backgroudColor: this.colors[4],
          textColor: this.getReadableTextColor(this.colors[4]),
          title: "2 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(2),
          active: false,
          id: 2,
          index: 4,
        },
        {
          backgroudColor: this.colors[5],
          textColor: this.getReadableTextColor(this.colors[5]),
          title: "1 Meses",
          subtitle: "",
          counter: await contractsService.countToExpiring(1),
          active: false,
          id: 1,
          index: 5,
        },
        {
          backgroudColor: this.colors[6],
          textColor: this.getReadableTextColor(this.colors[6]),
          title: "Este Mês",
          subtitle: "",
          counter: await contractsService.countToExpiring(0),
          active: false,
          id: 0,
          index: 6,
        },
        {
          backgroudColor: this.colors[7],
          textColor: this.getReadableTextColor(this.colors[7]),
          title: "Expirados",
          subtitle: "",
          counter: await contractsService.countExpired(),
          active: false,
          id: -1,
          index: 7,
        },
      ];
      return (this.streakLoading = true);
    },
    getReadableTextColor(backgroundColor) {
      const whiteContrast = chroma.contrast(backgroundColor, "#ffffff");
      const blackContrast = chroma.contrast(backgroundColor, "#000000");
      return whiteContrast > blackContrast ? "#ffffff" : "#000000";
    },
    getIconColor(monthsToEnd) {
      let statusInfo = this.streakStatus.find(
        (status) => status.id == monthsToEnd
      );
      return { color: this.colors[statusInfo.index] };
    },
    async streakSelectedChange(selected, merge) {
      this.loading = true;
      this.currentState = selected;
      if (selected.currentId === -1) {
        if (merge === true) {
          this.contracts = this.contracts.concat(
            await contractsService.getExpired()
          );
        } else {
          this.contracts = await contractsService.getExpired();
        }
        return (this.loading = false);
      }
      if (merge === true) {
        this.contracts = this.contracts.concat(
          await contractsService.toExpiring(selected.currentId)
        );
      } else {
        this.contracts = await contractsService.toExpiring(selected.currentId);
      }
      return (this.loading = false);
    },
    async showAll() {
      this.loading = true;
      this.contracts = [];
      for (const [index, status] of this.streakStatus.entries()) {
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
            currentId: status.id,
          },
          true
        );
      }
      return (this.loading = false);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return "";
      }
      const cleaned = phoneNumber.replace(/\s+/g, "");
      const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");

      return formatted;
    },
    composeEmail(contractInfo) {
      this.emailCompose.contract = contractInfo;
      this.emailCompose.header = `Enviar Email para ${contractInfo.client_name} | ${contractInfo.entity_name}`;
      this.emailCompose.response.to = [contractInfo.external_manager_email];
      this.emailCompose.response.cc = [contractInfo.renew_email];
      if (contractInfo.seller_email) {
        this.emailCompose.response.cc.push(contractInfo.seller_email);
      }
      this.getEmailTemplateEmail();
      return (this.emailCompose.show = true);
    },
    getEmailTemplateEmail() {
      let template = this.emailsTypes.find(
        (email) =>
          email.months_before_expiration ===
          this.emailCompose.contract.months_to_expire
      );
      this.emailCompose.template = template.id;
      this.emailCompose.response.subject = template.subject;
      this.emailCompose.response.content = template.body;
      if (template.extra_recipients) {
        if (
          template.extra_recipients.includes("Director") &&
          this.emailCompose.contract.external_direction_email
        ) {
          this.emailCompose.response.cc.push(
            this.emailCompose.contract.external_direction_email
          );
        }
        if (
          template.extra_recipients.includes("TeamLeader") &&
          this.emailCompose.contract.external_customer_management_email
        ) {
          this.emailCompose.response.cc.push(
            this.emailCompose.contract.external_customer_management_email
          );
        }
      }
      return this.setVariables();
    },
    setVariables() {
      this.emailCompose.response.subject =
        this.emailCompose.response.subject.replaceAll(
          "[entity_name]",
          this.emailCompose.contract.entity_name
        );
      this.emailCompose.response.subject =
        this.emailCompose.response.subject.replaceAll(
          "[product]",
          this.emailCompose.contract.product
        );
      this.emailCompose.response.content =
        this.emailCompose.response.content.replaceAll(
          "[endDate]",
          this.emailCompose.contract.end
        );
      this.emailCompose.response.content =
        this.emailCompose.response.content.replaceAll(
          "[seller]",
          this.emailCompose.contract.seller
        );
      this.emailCompose.response.content =
        this.emailCompose.response.content.replaceAll(
          "[entity_name]",
          this.emailCompose.contract.entity_name
        );
      return (this.emailCompose.templateChanged = false);
    },
    templateMailChanged() {
      let template = this.emailsTypes.find(
        (email) => email.id === this.emailCompose.template
      );
      this.emailCompose.template = template.id;
      this.emailCompose.response.subject = template.subject;
      this.emailCompose.response.content = template.body;
      return this.setVariables();
    },
    sendEmail() {
      let hasError = false;
      if (this.emailCompose.response.content == "") {
        hasError = true;
        this.emailCompose.response.errorContent = true;
      } else {
        this.emailCompose.response.errorContent = false;
      }
      if (this.emailCompose.response.subject == "") {
        hasError = true;
        this.emailCompose.response.errorSubject = true;
      } else {
        this.emailCompose.response.errorSubject = false;
      }

      if (this.emailCompose.response["to"].length == 0) {
        hasError = true;
        this.emailCompose.response.errorTo = true;
      } else {
        this.emailCompose.response.errorTo = false;
      }

      if (hasError || !this.emailCompose.contract) {
        return;
      }
      if (!this.emailCompose.templateChanged) {
        return this.confirmedSend();
      }
      let message = `O template do email foi alterado, tem a certeza que pretende enviar o email?`;
      this.$confirm.require({
        header: `Template Alterado`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.confirmedSend();
        },
        reject: () => {
          return;
        },
      });
    },
    confirmedSend() {
      this.emailCompose.loading = true;
      var formData = new FormData();
      formData.append(
        "months_before_expiration",
        this.emailCompose.contract.months_to_expire
      );
      formData.append("to", this.emailCompose.response.to);
      formData.append("cc", this.emailCompose.response.cc);
      formData.append("bcc", this.emailCompose.response.bcc);
      formData.append("subject", this.emailCompose.response.subject);
      formData.append("body", this.emailCompose.response.content);
      let attachments = this.$refs["attachmentInput"];
      if (attachments.files.length > 0) {
        attachments.files.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }
      return contractsService
        .sendMessage(this.emailCompose.contract.id, formData)
        .then((response) => {
          this.emailCompose.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Ocorreu um erro ao enviar o email",
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Email enviado com sucesso`,
            detail: ``,
            life: 3000,
          });
          return this.cancelSend();
        });
    },
    cancelSend() {
      return (this.emailCompose = {
        loading: false,
        show: false,
        header: null,
        template: null,
        templateChanged: null,
        contract: null,
        response: {
          to: [],
          cc: [],
          bcc: [],
          subject: "",
          content: "",
          errorTo: false,
          errorSubject: false,
          errorContent: false,
          errorStatus: false,
        },
      });
    },
    changeSellerCancel() {
      return (this.changeSellerInfo = {
        show: false,
        currentSeller: null,
        contractId: null,
      });
    },
    changeSeller(contract) {
      return (this.changeSellerInfo = {
        show: true,
        currentSeller: contract.seller,
        contractId: contract.id,
        contract: contract,
      });
    },
    changedSeller(info) {
      if (info.status == "ok") {
        this.changeSellerInfo.contract.seller = info.currenSeller.seller;
        this.changeSellerInfo.contract.seller_email =
          info.currenSeller.seller_email;
      }
      return this.changeSellerCancel();
    },
    changeExternalManager(contract) {
      return (this.changeExternalsInfo = {
        show: true,
        contractId: contract.id,
        contract: contract,
        currentExternalManager: contract.external_manager_id,
        currentExternalDirection: contract.external_direction_id,
        currentExternalCoordinator: contract.external_coordinator_id,
        currentExternalConsultant: contract.external_consultant_id,
        currentExternalCustomerManagement:
          contract.external_customer_management_id,
      });
    },
    changeExternalManagerCancel() {
      return (this.changeExternalsInfo = {
        show: false,
        contractId: null,
        currentExternalManager: null,
        currentExternalDirection: null,
        currentExternalCoordinator: null,
        currentExternalConsultant: null,
        currentExternalCustomerManagement: null,
      });
    },
    changedExternalManager(info) {
      if (info.status == "ok") {
        let externals = info.currenExternals;
        this.changeExternalsInfo.contract.external_manager_id =
          externals.external_manager_id;
        this.changeExternalsInfo.contract.external_manager_email =
          externals.external_manager_email;
        this.changeExternalsInfo.contract.external_manager_name =
          externals.external_manager_name;
        this.changeExternalsInfo.contract.external_manager_telephone =
          externals.external_manager_telephone;
        this.changeExternalsInfo.contract.external_manager_type =
          externals.external_manager_type;

        this.changeExternalsInfo.contract.external_direction_id =
          externals.external_direction_id;
        this.changeExternalsInfo.contract.external_direction_email =
          externals.external_direction_email;
        this.changeExternalsInfo.contract.external_direction_name =
          externals.external_direction_name;
        this.changeExternalsInfo.contract.external_direction_telephone =
          externals.external_direction_telephone;
        this.changeExternalsInfo.contract.external_direction_type =
          externals.external_direction_type;

        this.changeExternalsInfo.contract.external_coordinator_id =
          externals.external_coordinator_id;
        this.changeExternalsInfo.contract.external_coordinator_email =
          externals.external_coordinator_email;
        this.changeExternalsInfo.contract.external_coordinator_name =
          externals.external_coordinator_name;
        this.changeExternalsInfo.contract.external_coordinator_telephone =
          externals.external_coordinator_telephone;
        this.changeExternalsInfo.contract.external_coordinator_type =
          externals.external_coordinator_type;

        this.changeExternalsInfo.contract.external_consultant_id =
          externals.external_consultant_id;
        this.changeExternalsInfo.contract.external_consultant_email =
          externals.external_consultant_email;
        this.changeExternalsInfo.contract.external_consultant_name =
          externals.external_consultant_name;
        this.changeExternalsInfo.contract.external_consultant_telephone =
          externals.external_consultant_telephone;
        this.changeExternalsInfo.contract.external_consultant_type =
          externals.external_consultant_type;

        // this.changeExternalsInfo.contract.external_customer_management_id =
        //   externals.external_customer_management_id;
        // this.changeExternalsInfo.contract.external_customer_management_email =
        //   externals.external_customer_management_email;
        // this.changeExternalsInfo.contract.external_customer_management_name =
        //   externals.external_customer_management_name;
        // this.changeExternalsInfo.contract.external_customer_management_telephone =
        //   externals.external_customer_management_telephone;
        // this.changeExternalsInfo.contract.external_customer_management_type =
        //   externals.external_customer_management_type;
      }
      return this.changeExternalManagerCancel();
    },
  },
};
</script>
<style>
.circle {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.p-chips-token {
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}

.error {
  color: #dc3545 !important;
}

.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}
</style>
