<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '45vw' }"
      :header="'Alterar Vendedor do Contarto'"
      :modal="true"
      :closable="false"
    >
      <form
        name="ContractsChangeExternal"
        class="p-col-12"
        @submit.prevent="saveExternals"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                name="externalManager"
                :options="listExternalManager"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="externalManager"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('externalManager') },
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  <span>
                    {{ slotProps.option.name }}
                  </span>
                  <span v-if="slotProps.option.email">
                    | {{ slotProps.option.email }}
                  </span>
                  <span v-if="slotProps.option.telephone">
                    | {{ slotProps.option.telephone }}
                  </span>
                  <span v-if="slotProps.option.type">
                    | {{ slotProps.option.type }}
                  </span>
                </template>
              </Dropdown>
              <label for="inputtext">Selecionar Comercial</label>
            </span>
            <small
              v-if="errors.has('externalManager')"
              class="p-error"
              role="alert"
            >
              Comercial é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                name="externalDirection"
                :options="listExternalManager"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="externalDirection"
                v-bind:class="[
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  <span>
                    {{ slotProps.option.name }}
                  </span>
                  <span v-if="slotProps.option.email">
                    | {{ slotProps.option.email }}
                  </span>
                  <span v-if="slotProps.option.telephone">
                    | {{ slotProps.option.telephone }}
                  </span>
                  <span v-if="slotProps.option.type">
                    | {{ slotProps.option.type }}
                  </span>
                </template>
              </Dropdown>
              <label for="inputtext">Selecionar Direção</label>
            </span>
            <small
              v-if="errors.has('externalDirection')"
              class="p-error"
              role="alert"
            >
              Direção é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                name="externalCoordinator"
                :options="listExternalManager"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="externalCoordinator"
                v-bind:class="[
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  <span>
                    {{ slotProps.option.name }}
                  </span>
                  <span v-if="slotProps.option.email">
                    | {{ slotProps.option.email }}
                  </span>
                  <span v-if="slotProps.option.telephone">
                    | {{ slotProps.option.telephone }}
                  </span>
                  <span v-if="slotProps.option.type">
                    | {{ slotProps.option.type }}
                  </span>
                </template>
              </Dropdown>
              <label for="inputtext">Selecionar Coordenador</label>
            </span>
            <small
              v-if="errors.has('externalCoordinator')"
              class="p-error"
              role="alert"
            >
            Coordenador é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                name="externalConsultant"
                :options="listExternalManager"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="externalConsultant"
                v-bind:class="[
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  <span>
                    {{ slotProps.option.name }}
                  </span>
                  <span v-if="slotProps.option.email">
                    | {{ slotProps.option.email }}
                  </span>
                  <span v-if="slotProps.option.telephone">
                    | {{ slotProps.option.telephone }}
                  </span>
                  <span v-if="slotProps.option.type">
                    | {{ slotProps.option.type }}
                  </span>
                </template>
              </Dropdown>
              <label for="inputtext">Selecionar Consultor</label>
            </span>
            <small
              v-if="errors.has('externalConsultant')"
              class="p-error"
              role="alert"
            >
            Consultor é obrigatório
            </small>
          </div>
          <!-- <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                name="externalCustomerManagement"
                :options="listExternalManager"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="externalCustomerManagement"
                v-bind:class="[
                  'form-control',
                ]"
              >
                <template #option="slotProps">
                  <span>
                    {{ slotProps.option.name }}
                  </span>
                  <span v-if="slotProps.option.email">
                    | {{ slotProps.option.email }}
                  </span>
                  <span v-if="slotProps.option.telephone">
                    | {{ slotProps.option.telephone }}
                  </span>
                  <span v-if="slotProps.option.type">
                    | {{ slotProps.option.type }}
                  </span>
                </template>
              </Dropdown>
              <label for="inputtext">Selecionar Gestor Cliente</label>
            </span>
            <small
              v-if="errors.has('externalCustomerManagement')"
              class="p-error"
              role="alert"
            >
            Gestor Cliente é obrigatório
            </small>
          </div> -->
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveExternals"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import contractsService from "../../services/contracts.services";
import externalManagerService from "../../services/externalManager.service";
export default {
  name: "ContractsChangeExternals",
  props: [
    "showToast",
    "show",
    "contractId",
    "currentExternalManager",
    "currentExternalDirection",
    "currentExternalCoordinator",
    "currentExternalConsultant",
    "currentExternalCustomerManagement",
  ],
  watch: {
    show() {
      if (this.show === true) {
        this.externalManager = this.currentExternalManager;
        this.externalDirection = this.currentExternalDirection;
        this.externalCoordinator = this.currentExternalCoordinator;
        this.externalConsultant = this.currentExternalConsultant;
        this.externalCustomerManagement = this.currentExternalCustomerManagement;
      }
      this.$validator.pause();
      this.$validator.reset();
    },
  },
  data() {
    return {
      externalManager: null,
      externalDirection: null,
      externalCoordinator: null,
      externalConsultant: null,
      externalCustomerManagement: null,
      listExternalManager: [],
    };
  },
  async created() {
    this.listExternalManager = await externalManagerService.getAll();
    this.externalManager = this.currentExternalManager;
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    saveExternals() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          externalManagerId: this.externalManager
        }
        if (this.externalDirection && this.externalDirection != '') {
          params.externalDirectionId = this.externalDirection;
        }
        if (this.externalCoordinator && this.externalCoordinator != '') {
          params.externalCoordinatorId = this.externalCoordinator;
        }
        if (this.externalConsultant && this.externalConsultant != '') {
          params.externalConsultantId = this.externalConsultant;
        }
        // if (this.externalCustomerManagement && this.externalCustomerManagement != '') {
        //   params.externalCustomerManagementId = this.externalCustomerManagement;
        // }
        return contractsService
          .changeExternalManager(this.contractId, params)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o Comercial do Contrato",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setContractExternalManager", {
                status: "error",
                currenExternals: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Comercial do contrato alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setContractExternalManager", {
              status: "ok",
              currenExternals: response,
            });
          });
      });
    },
  },
};
</script>
